import React, { useEffect, useState } from 'react';
import { Facebook,Linkedin } from 'lucide-react';

const SocialLink = ({ icon: Icon, link }) => (
  <li>
    <a href={link} className="text-gray-500 hover:text-[#00A0D8] transition-colors duration-300" target="_blank" rel="noopener noreferrer">
      <Icon size={24} />
    </a>
  </li>
);

const TimeUnit = ({ value, label }) => (
  <li>
    <div className="font-bold text-white rounded-full border-dotted border-gray-700 border-4 flex items-center justify-center text-2xl lg:text-4xl h-16 lg:h-24 w-16 lg:w-24">
      {value}
    </div>
    <p className="text-center text-xs mt-2">{label}</p>
  </li>
);

const ComingSoon = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
  });

  useEffect(() => {
    const countDownEndDate = new Date("January 10, 2025 09:00:00").getTime();

    const updateCountdown = () => {
      const timeNow = new Date().getTime();
      const timeLeft = countDownEndDate - timeNow;

      if (timeLeft > 0) {
        const days = String(Math.floor(timeLeft / (1000 * 60 * 60 * 24))).padStart(2, '0');
        const hours = String(Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, '0');
        const minutes = String(Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
        const seconds = String(Math.floor((timeLeft % (1000 * 60)) / 1000)).padStart(2, '0');

        setTimeLeft({ days, hours, minutes, seconds });
      }
    };

    updateCountdown();
    const intervalId = setInterval(updateCountdown, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const timeUnits = [
    { value: timeLeft.days, label: 'Days' },
    { value: timeLeft.hours, label: 'Hours' },
    { value: timeLeft.minutes, label: 'Minutes' },
    { value: timeLeft.seconds, label: 'Seconds' }
  ];

  return (
    <div className="font-['Noto_Sans']">
      <header className="w-full bg-[url('./image/home1-CQqxARI7.png')] bg-cover bg-center">
        <div className="w-full min-h-screen md:w-1/2 bg-gradient-to-b from-gray-900/90 to-gray-900/95 p-10 flex">
          <div className="w-full text-center my-auto">
            <h1 className="font-bold text-5xl text-[#00A0D8] mb-10 md:text-right transition-colors duration-300">
              Application en Cours de Création.
            </h1>

            <h6 className="font-['Montserrat'] font-bold uppercase text-4xl md:text-5xl lg:text-6xl mb-14 md:-mr-3 text-white md:text-right">
              MULTILAB <span className="text-white/70 md:-mr-20 lg:text-2xl">s.a.</span>
            </h6>

            <div className="w-full text-left flex mb-10">
              <ul className="w-full flex place-content-center md:place-content-end gap-5 mx-auto text-gray-50">
                {timeUnits.map((unit, index) => (
                  <TimeUnit key={index} value={unit.value} label={unit.label} />
                ))}
              </ul>
            </div>

            <p className="text-base mb-10 text-gray-200 md:text-justify leading-relaxed">
              MULTILAB s.a , c'est opter pour l'expertise et la fiabilité d'un laboratoire accrédité, 
              capable de réaliser des analyses précises et rapides. Grâce à notre présence sur tout 
              le territoire tunisien, nous offrons un service de proximité, adapté aux besoins 
              spécifiques de chaque client. Notre engagement envers la qualité et la sécurité 
              sanitaire garantit des résultats conformes aux normes les plus strictes. Avec une 
              gamme étendue de méthodes d'analyse, MULTILAB s.a est votre partenaire de confiance 
              pour assurer la sécurité et la qualité de vos produits.
            </p>

            <div className="w-full text-left flex">
              <ul className="w-full flex place-content-center md:place-content-end gap-10 mx-auto">
                <SocialLink   icon={Facebook} link="https://www.facebook.com/Multilab.sa/" />
                <SocialLink  icon={Linkedin} link="https://tn.linkedin.com/in/multilab-s-a-b19788707" />
               
              </ul>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default ComingSoon;